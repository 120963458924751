import app from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyDprGj3l_XNArxjvMdIOQzSh9OVpZ-04Aw",
    authDomain: "redlabsblog.firebaseapp.com",
    databaseURL: "https://redlabsblog.firebaseio.com",
    projectId: "redlabsblog",
    storageBucket: "redlabsblog.appspot.com",
    messagingSenderId: "31439932723",
    appId: "1:31439932723:web:7b8a6bdcc96e98f9e8c246",
    measurementId: "G-7EBHJWK8XF"
  };

class Firebase {
    constructor() {
        console.log("firebase constructor called")
        app.initializeApp(config)

        const db = app.firestore();
          // ADD THESE LINES
        if (window.location.hostname === "localhost") {
            console.log("localhost detected!");
            db.settings({
            host: "localhost:8080",
            ssl: false
            });
        }
    }

    comments = pid => app.firestore().collection('comments').where('slug', '==', pid).orderBy('created', 'desc');
    newComment = (slug, author, content) => app.firestore().collection('comments').doc().set({author, slug, content, created: app.firestore.Timestamp.now() });
}

export default Firebase;