import React, {useState} from "react"
import { FirebaseProvider } from "./src/components/firebase"
import "prismjs/themes/prism-tomorrow.css"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Lato',sans-serif",
    fontSize: '15px',
    fontWeight: '200',
  }
  });

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
      <FirebaseProvider>{element}</FirebaseProvider>
      </ThemeProvider>
  )