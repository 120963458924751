import React, { useEffect, useState } from "react"
import Firebase from "./firebase"

const FirebaseContext = React.createContext(null)

const useFirebase = () => React.useContext(FirebaseContext)

export const FirebaseProvider = props => {
  const [firebase, setFirebase] = useState(undefined)

  useEffect(() => {
    setFirebase(new Firebase())
  }, [])

  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  )
}

export default useFirebase